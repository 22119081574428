.standardButton {
  font-family: "Lekton-Regular", monospace;
  color: var(--f1);
  background: var(--b1);
  border-color: var(--f1);
  border-radius: 4px;
  border-width: 0.75px;
  padding: 5px 7px;
  min-width: 60px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

@media (hover: hover) {
  .standardButton:hover {
    background-color: var(--b2);
  }
}

.standardButton:disabled,
.standardButton[disabled] {
  cursor: not-allowed;
  border: solid;
  border-color: var(--b2);
  background-color: var(--b3);
  color: var(f1);
}

.standardButton:active {
  background-color: var(--b3);
}

.standardButton:focus {
  outline: 0;
}
