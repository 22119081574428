.container {
  font-family: "Lekton-Regular", monospace;
}

.content {
  overflow: hidden;
  background-color: var(--b1);
  position: absolute;
  width: 100%;
  height: calc(100% - 50px);
}

#ascii {
  font-family: "Lekton-Regular", monospace;
  text-align: center;
  width: 100%;
  height: 100%;
  color: var(--f1);
  overflow-y: hidden;
}

@media (orientation: landscape) {
  #ascii {
    font-size: 0.55vw;
    line-height: 0.55vw;
    letter-spacing: -0.005vw;
  }
}

@media (orientation: portrait) {
  #ascii {
    font-size: 0.75vw;
    line-height: 0.8vw;
    letter-spacing: -0.05vw;
  }
}

.standard-text {
  color: var(--f1);
  font-family: "Lekton-Regular", monospace;
}

.standard-text-link {
  color: var(--s1);
}

.standard-text-container {
  text-align: right;
  font-family: "Lekton-Regular", monospace;
}

.mainContainer {
  position: relative;
  width: 10;
}
.center-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 100vw;
  overflow-x: hidden;
  max-height: 100vh;
  overflow-y: hidden;
  transform: translate(-50%, -50%);
}

.top-left-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 2vh;
  margin-left: 2vw;
}

.top-right-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 2vh;
  margin-right: 2vw;
}

.bottom-left-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 2vh;
  margin-left: 2vw;
}

.bottom-right-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 1vh;
  margin-right: 1vw;
}

.standardBorder {
  border-color: var(--f1);
  border-radius: 4px;
  border-width: 0.75px;
  padding: 5px 7px;
}
